import firebase from "firebase";
// import "firebase/firestore";
// import "firebase/auth";

import firebaseConfig from "../firebaseConfig";
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const getAdminPrivileges = async (userId) => {
	// console.log("firestore UserId: ", userId);
	const docRef = db.collection("userData").doc(userId);
	return await docRef.get().then((doc) => {
		if (doc.exists) {
			// console.log("firestore admin: ", doc.data().admin);
			return doc.data().admin;
		} else {
			// console.log("firestore returning false");
			return false;
		}
	});
};

// export const getAllUsers = async () => {
// 	const db = firebase.firestore();
// 	console.log("firestore UserId: ");
// 	const docRef = db.collection("userData").doc(userId);
// 	return await docRef.get().then((doc) => {
// 		if (doc.exists) {
// 			console.log("firestore admin: ", doc.data().admin);
// 			return doc.data().admin;
// 		} else {
// 			console.log("firestore returning false");
// 			return false;
// 		}
// 	});
// };

export const getAllProperties = async () => {
	const snapshot = await db.collection("properties").get();
	return snapshot.docs.map((doc) => doc.data());
};

export const getAllUsers = async () => {
	// console.log("getting all users");
	const snapshot = await db.collection("userData").get();
	return snapshot.docs.map((doc) => doc.data());
};

export const getUserInfo = async (uid) => {
	const docRef = await db.collection("userData").doc(uid);
	return docRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				// console.log("Document data:", doc.data());

				return doc.data();
			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
			}
		})
		.catch((error) => {
			console.log("Error getting document:", error);
		});
};

export const getPropertyInfo = async (pid) => {
	const docRef = await db.collection("properties").doc(pid);
	return docRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				// console.log("Document data:", doc.data());
				return doc.data();
			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
			}
		})
		.catch((error) => {
			console.log("Error getting document:", error);
		});
};

// export const getPropertyInfo = async (arr) => {
// 	return await arr.map(async (pid) => {
// 		await db
// 			.collection("properties")
// 			.doc(pid)
// 			.get()
// 			.then((doc) => {
// 				console.log("propData: ", doc.data());
// 				return doc.data();
// 			});
// 	});

// 	// docRef
// 	// 	.get()
// 	// 	.then((doc) => {
// 	// 		if (doc.exists) {
// 	// 			console.log("Document data:", doc.data());
// 	// 			return doc.data();
// 	// 		} else {
// 	// 			// doc.data() will be undefined in this case
// 	// 			console.log("No such document!");
// 	// 		}
// 	// 	})
// 	// 	.catch((error) => {
// 	// 		console.log("Error getting document:", error);
// 	// 	});
// };

export const updateUserProperties = async (uid, pid, isActive) => {
	console.log("isActive: ", isActive);
	if (isActive) {
		await db
			.collection("userData")
			.doc(uid)
			.update({
				properties: firebase.firestore.FieldValue.arrayUnion(pid),
			});
		console.log("property added");
		return;
	} else {
		await db
			.collection("userData")
			.doc(uid)
			.update({
				properties: firebase.firestore.FieldValue.arrayRemove(pid),
			});
		console.log("property removed");
		return;
	}
};
