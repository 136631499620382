import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DataRetrieval from "./DataRetrieval";
import SignIn from "./SignIn";

import {
	FirebaseAuthProvider,
	IfFirebaseAuthed,
	IfFirebaseUnAuthed,
} from "@react-firebase/auth";
import firebase from "firebase";
import config from "./firebaseConfig";
const { providerId, isSignedIn, ...authProviderConfig } = config;

function App() {
	// const [admin, setAdmin] = useState(false);

	return (
		<FirebaseAuthProvider {...authProviderConfig} firebase={firebase}>
			<React.Fragment>
				<IfFirebaseUnAuthed>
					<SignIn firebase={firebase} />
				</IfFirebaseUnAuthed>
				<IfFirebaseAuthed>
					<DataRetrieval firebase={firebase} />
				</IfFirebaseAuthed>
			</React.Fragment>
		</FirebaseAuthProvider>
	);
}

export default App;
