import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Iframe from "react-iframe";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const Dashboard = (props) => {
	// "https://airtable.com/embed/shrVRHPps71gmJxvd?backgroundColor=cyan&viewControls=on"
	// const [viewId, setViewId] = useState("");
	const [viewURL, setViewURL] = useState();
	// const [activePropertyBaseId2, setActivePropertyBaseId2] = useState("");

	const handleSelectedPropertyChange = (e) => {
		const index = e.target.selectedIndex;
		const optionElement = e.target.childNodes[index];
		const baseId = optionElement.getAttribute("data-base-id");
		// setActivePropertyBaseId2(baseId);
		console.log("in the view");
		props.indivPropertyInfo.map((property) => {
			if (property.baseId === baseId) {
				setViewURL(
					`https://airtable.com/embed/${property.view}?backgroundColor=cyan&viewControls=on`
				);
			}
			return null;
		});
	};

	useEffect(() => {
		// get view ID to be displayed
		// setActivePropertyBaseId2(props.activePropertyBaseId);
		// props.indivPropertyInfo.map((property) => {
		// 	if (property.baseId === props.activePropertyBaseId) {
		// 		setViewURL(
		// 			`https://airtable.com/embed/${property.view}?backgroundColor=cyan&viewControls=on`
		// 		);
		// 	}
		// });
		setViewURL(
			`https://airtable.com/embed/${props.indivPropertyInfo[0].view}?backgroundColor=cyan&viewControls=on`
		);
	}, [props.indivPropertyInfo]);

	return (
		<Container>
			<h2 className="text-center mt-4 mb-3">Reported Data</h2>
			<Form>
				<Form.Row className="justify-content-around">
					<Col xs={4} className="my-1">
						<Form.Group controlId="formSelectProperty">
							<Form.Control
								as="select"
								custom
								name="selectedProperty"
								onChange={(e) => handleSelectedPropertyChange(e)}
							>
								{props.indivPropertyInfo
									? props.indivPropertyInfo.sort().map((property, i) => {
											return (
												<option key={i} data-base-id={property.baseId}>
													{property.name}
												</option>
											);
									  })
									: null}
							</Form.Control>
						</Form.Group>
					</Col>
				</Form.Row>
			</Form>
			<Iframe
				url={viewURL}
				width="100%"
				height="533px"
				id="myId"
				className="airtable-embed"
				display="initial"
				position="relative"
			/>
		</Container>
	);
};

export default Dashboard;
