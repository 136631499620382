const firebaseConfig = {
	apiKey: "AIzaSyB8LXSK1FSMo0DseACOGeTZFycaLXJIb0Y",
	authDomain: "highlake-d9d5a.firebaseapp.com",
	projectId: "highlake-d9d5a",
	storageBucket: "highlake-d9d5a.appspot.com",
	messagingSenderId: "362959492245",
	appId: "1:362959492245:web:33cf49ec719f5a5f4b779c",
	measurementId: "G-M67E9E5FNH",
};

export default firebaseConfig;
