import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import * as services from "./services/firestore";

const Admin = () => {
	const [allUsers, setAllUsers] = useState(null);
	const [activeUser, setActiveUser] = useState("");
	const [indexOfUser, setIndexOfUser] = useState(0);
	const [allProperties, setAllProperties] = useState("");
	const [activeProperties, setActiveProperties] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const allProperties = await services.getAllProperties();
			// console.log("allProperties received: ", allProperties);
			const allUsers = await services.getAllUsers();
			// console.log("allUsers received: ", allUsers);

			const tempActiveUser = allUsers[0];

			setAllUsers(allUsers);
			setAllProperties(allProperties);
			setActiveUser(tempActiveUser);

			const tempActiveProperties = tempActiveUser.properties.map(
				(property) => property
			);

			setActiveProperties(tempActiveProperties);
		};

		fetchData();
	}, []);

	const handleUserDropdownChange = (e) => {
		// search for users full name in all users array and get index
		let tempIndexOfUser;
		allUsers.map((user, i) => {
			if (user.full_name === e.target.value) {
				// console.log("index1", i);
				tempIndexOfUser = i;
				setIndexOfUser(i);
				// console.log("index2", tempIndexOfUser);
			}
			return null;
		});

		// use index to get users information from all users array
		const tempActiveUser = allUsers[tempIndexOfUser];
		const tempActiveProperties = tempActiveUser.properties.map(
			(property) => property
		);

		// set active users state
		setActiveUser(allUsers[tempIndexOfUser]);
		setActiveProperties(tempActiveProperties);
	};

	const handlePropertyClick = (pid, isActive) => {
		const updateUserProperties = async () => {
			await services.updateUserProperties(activeUser.uid, pid, !isActive);
			// console.log("done updating user properties");
			const allUsers = await services.getAllUsers();
			const tempActiveUser = allUsers[indexOfUser];
			const tempActiveProperties = tempActiveUser.properties.map(
				(property) => property
			);
			setActiveProperties(tempActiveProperties);
			setAllUsers(allUsers);
			// console.log("done getting user data");
		};
		updateUserProperties();
	};

	return (
		<Container className="shadow p-3 mt-3">
			<Row>
				<Col xs={10} md={5} lg={3}>
					<Form.Group controlId="formSelectUser">
						User
						<Form.Control
							as="select"
							custom
							value={activeUser.full_name}
							name="user"
							onChange={(e) => handleUserDropdownChange(e)}
						>
							{/* <option key={100}></option> */}
							{allUsers
								? allUsers.map((user, i) => {
										return <option key={i}>{user.full_name}</option>;
								  })
								: null}
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<ListGroup>
					{allProperties
						? allProperties.sort().map((property, i) => {
								let isActive = false;
								activeProperties.map((pid) => {
									if (pid === property.pid) {
										isActive = true;
									}
									return null;
								});
								return (
									<ListGroup.Item
										className="ml-3"
										active={isActive}
										key={i}
										onClick={() => {
											handlePropertyClick(property.pid, isActive);
										}}
									>
										{property.name}
									</ListGroup.Item>
								);
						  })
						: null}
				</ListGroup>
			</Row>
		</Container>
	);
};

export default Admin;
