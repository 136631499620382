import React, { useState, useEffect } from "react";
import * as services from "./services/firestore";
import NavBarCustom from "./NavBarCustom";
import Dashboard from "./Dashboard";
import Admin from "./Admin";
import SubmitReport from "./SubmitReport";

const DataRetrieval = (props) => {
	const [userInfo, setUserInfo] = useState({
		uid: "",
		properties: [],
		full_name: "",
		admin: false,
	});
	const [indivPropertyInfo, setIndivPropertyInfo] = useState([]);
	const [activePropertyBaseId, setActivePropertyBaseId] = useState("");
	const [view, setView] = useState("submit");

	useEffect(() => {
		const fetchData = async () => {
			let tempUserInfo;
			let tempIndivPropertyInfo;

			// get uid of current person logged in
			let user = props.firebase.auth().currentUser;

			// get user profile information
			await services.getUserInfo(user.uid).then((res) => {
				tempUserInfo = res;

				// gets information about each property
				return Promise.all(
					res.properties.map(async (pid) => {
						return await services.getPropertyInfo(pid);
					})
				).then((res) => {
					tempIndivPropertyInfo = res;
					return;
				});
			});

			setUserInfo(tempUserInfo);
			setIndivPropertyInfo(tempIndivPropertyInfo);
			setActivePropertyBaseId(tempIndivPropertyInfo[0].baseId);
		};
		fetchData();
	}, [props.firebase]);
	return (
		<React.Fragment>
			<NavBarCustom
				firebase={props.firebase}
				view={view}
				setView={setView}
				admin={userInfo.admin}
				uid={userInfo.uid}
			/>

			{view === "submit" ? (
				<SubmitReport
					userInfo={userInfo}
					indivPropertyInfo={indivPropertyInfo}
					activePropertyBaseId={activePropertyBaseId}
					setActivePropertyBaseId={setActivePropertyBaseId}
				/>
			) : (
				<React.Fragment></React.Fragment>
			)}

			{view === "dashboard" ? (
				<Dashboard
					activePropertyBaseId={activePropertyBaseId}
					indivPropertyInfo={indivPropertyInfo}
					setActivePropertyBaseId={setActivePropertyBaseId}
				/>
			) : (
				<React.Fragment></React.Fragment>
			)}

			{view === "admin" ? <Admin /> : <React.Fragment></React.Fragment>}
		</React.Fragment>
	);
};

export default DataRetrieval;
