import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";

const templateList = {
  generalList: {
    email: "",
    password: "",
  },
  viewList: {
    loginScreen: true,
  },
  resetList: {
    resetEmail: "",
  },
};

// const cookies = new Cookies();

function SignIn(props) {
  // constants

  const handleShowForgotPassword = () => setShowForgotPassword(true);
  const handleCloseForgotPassword = () => setShowForgotPassword(false);

  const handleShowIncorrectPassword = () => setShowIncorrectPassword(true);

  const handleShowPasswordResetInitiated = () =>
    setShowPasswordResetInitiated(true);

  // states

  const [generalList, setGeneralList] = useState({
    ...templateList.generalList,
  });

  const [resetList, setResetList] = useState({
    ...templateList.resetList,
  });

  // const [retrievedUid, setRetrievedUid] = useState(null);

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showIncorrectPassword, setShowIncorrectPassword] = useState(false);
  const [showPasswordResetInitiated, setShowPasswordResetInitiated] =
    useState(false);

  // handlers

  const handleGeneralInputChange = (e) => {
    const { name, value } = e.target;
    const list = { ...generalList };
    list[name] = value;
    setGeneralList(list);
  };

  const handleSignIn = (email, password) => {
    props.firebase
      .app()
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        // console.log("data: ", data);
        // console.log("uid: ", data.user.uid);
        // setRetrievedUid(data.user.uid);
        // cookies.set("browserUid", data.user.uid);
      })
      .catch((err) => {
        console.error("error", err.message);
        handleShowIncorrectPassword();
      });
  };

  const handleResetEmail = (e) => {
    const { name, value } = e.target;
    const list = { ...resetList };
    list[name] = value;
    setResetList(list);
  };

  const handleSubmitReset = (e) => {
    const email = resetList.resetEmail;
    props.firebase
      .app()
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log("password reset request submitted successfully");
        handleShowPasswordResetInitiated();
        handleCloseForgotPassword();
      });
  };

  return (
    <Container className="mt-3">
      <Form.Row className="justify-content-around">
        <Col xs={12} md={6} lg={4} className="my-1">
          <Form.Group controlId="formSignInEmail">
            <Form.Label className="mt-2">Email Address: </Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={generalList.email}
              onChange={(e) => handleGeneralInputChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="formSignInPassword">
            <Form.Label className="mt-2">Password: </Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={generalList.password}
              onChange={(e) => handleGeneralInputChange(e)}
            />
            <div className="d-flex justify-content-between">
              <Button
                variant="outline-primary"
                className="mt-3"
                onClick={() =>
                  handleSignIn(generalList.email, generalList.password)
                }
              >
                Login
              </Button>
              <Button
                variant="outline-secondary"
                className="ml-5 align-self-end"
                onClick={() => handleShowForgotPassword()}
              >
                Reset Password
              </Button>
            </div>
          </Form.Group>
          {showIncorrectPassword && (
            <Form.Group>
              <div>
                <Alert variant="danger">
                  Login incorrect. Please try again.
                </Alert>
              </div>
            </Form.Group>
          )}

          {showPasswordResetInitiated && (
            <Form.Group>
              <div>
                <Alert variant="success">
                  Password instructions sent to email.
                </Alert>
              </div>
            </Form.Group>
          )}
        </Col>
      </Form.Row>

      <Modal show={showForgotPassword} onHide={handleCloseForgotPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formResetPassword">
            <Form.Control
              type="email"
              name="resetEmail"
              value={resetList.resetEmail}
              placeholder="Email Address"
              onChange={(e) => handleResetEmail(e)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={handleCloseForgotPassword}
          >
            Cancel
          </Button>
          <Button variant="outline-primary" onClick={handleSubmitReset}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default SignIn;
