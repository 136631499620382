import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

import axios from "axios";

const templateList = {
    generalList: {
        incomeGoal: "",
        totalBilled: "",
        totalCollected: "",
        employeeCredits: "",
        housingRentDue: "",
        currentMonthDue: "",
        previousMonthDue: "",
        numberOfVacantUnits: "",
    },
    moveInList: {
        unitNumber: "",
        date: "",
        rentalRate: "",
    },
    moveOutList: {
        unitNumber: "",
        date: "",
        rentalStatus: "",
    },
    expiringList: {
        unitNumber: "",
        date: "",
        previousRentalRate: "",
        postRentalRate: "",
        renew: "Renewed?",
        renewNote: "",
    },
    delinquentList: {
        unitNumber: "",
        amountDelinquent: "",
        noticeToVacate: "",
        courtDateInfo: "",
    },
    evictionList: {
        unitNumber: "",
        amountDelinquent: "",
        noticeToVacate: "",
        courtDateInfo: "",
    },
};

function SubmitReport(props) {
    const [generalList, setGeneralList] = useState({
        ...templateList.generalList,
    });
    const [moveInList, setMoveInList] = useState([
        { ...templateList.moveInList },
    ]);
    const [moveOutList, setMoveOutList] = useState([
        { ...templateList.moveOutList },
    ]);
    const [expiringList, setExpiringList] = useState([
        { ...templateList.expiringList },
    ]);
    const [delinquentList, setDelinquentList] = useState([
        { ...templateList.delinquentList },
    ]);
    const [evictionList, setEvictionList] = useState([
        { ...templateList.evictionList },
    ]);

    const [showSubmissionComplete, setShowSubmissionComplete] = useState(false);
    const handleCloseSubmissionComplete = () =>
        setShowSubmissionComplete(false);

    // handle input change
    const handleInputChange = (e, index, varList, setList) => {
        const { name, value } = e.target;
        const list = [...varList];
        list[index][name] = value;
        setList(list);
    };

    const handleGeneralInputChange = (e) => {
        const { name, value } = e.target;
        // console.log(name);
        // console.log(value);
        const list = { ...generalList };
        list[name] = value;
        setGeneralList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index, varList, setList) => {
        const list = [...varList];
        list.splice(index, 1);
        setList(list);
    };

    // handle click event of the Add button
    const handleAddClick = (varList, setList, varListText) => {
        // console.log({ ...templateList[varListText] });
        setList([...varList, { ...templateList[varListText] }]);
    };

    const handleSelectedPropertyChange = (e) => {
        const index = e.target.selectedIndex;
        const optionElement = e.target.childNodes[index];
        const baseId = optionElement.getAttribute("data-base-id");
        props.setActivePropertyBaseId(baseId);
    };

    const handleSubmit = () => {
        const activePropertyBaseId = props.activePropertyBaseId;
        const data = {
            activePropertyBaseId,
            generalList,
            moveInList,
            moveOutList,
            expiringList,
            delinquentList,
            evictionList,
        };
        axios
            //   .post("http://localhost:4000/api/highlake/submit", data)
            .post("https://app.highlakecapital.com/api/highlake/submit", data)
            .then((res) => {
                // console.log(res);
                // console.log(res.data);
                setShowSubmissionComplete(true);

                // clear form
                setGeneralList({
                    ...templateList.generalList,
                });
                setMoveInList([{ ...templateList.moveInList }]);
                setMoveOutList([{ ...templateList.moveOutList }]);
                setExpiringList([{ ...templateList.expiringList }]);
                setDelinquentList([{ ...templateList.delinquentList }]);
                setEvictionList([{ ...templateList.evictionList }]);
            });
    };

    return (
        <Container>
            <h2 className="text-center mt-4 mb-3">Weekly Report</h2>
            <Form>
                <Form.Row className="justify-content-around">
                    <Col xs={4} className="my-1">
                        <Form.Group controlId="formSelectProperty">
                            <Form.Control
                                as="select"
                                custom
                                // value="hi there"
                                name="selectedProperty"
                                onChange={(e) =>
                                    handleSelectedPropertyChange(e)
                                }
                            >
                                {props.indivPropertyInfo
                                    ? props.indivPropertyInfo
                                          .sort()
                                          .map((property, i) => {
                                              return (
                                                  <option
                                                      key={i}
                                                      data-base-id={
                                                          property.baseId
                                                      }
                                                  >
                                                      {property.name}
                                                  </option>
                                              );
                                          })
                                    : null}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Form>
            <Form>
                {/* Income */}
                <Container className="shadow p-3">
                    <h4>Income</h4>
                    <Form.Row className="justify-content-left">
                        <Col xs={{ span: 4, offset: 1 }} className="my-1">
                            <Form.Group controlId="formIncomeGoal">
                                <Form.Label>Income Goal: </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="incomeGoal"
                                    value={generalList.incomeGoal}
                                    onChange={(e) =>
                                        handleGeneralInputChange(e)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="justify-content-around">
                        <Col xs={4} className="my-1">
                            <Form.Group controlId="formTotalBilled">
                                <Form.Label>Total Billed: </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="totalBilled"
                                    value={generalList.totalBilled}
                                    onChange={(e) =>
                                        handleGeneralInputChange(e)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4} className="my-1">
                            <Form.Group controlId="formTotalCollected">
                                <Form.Label>Total Collected: </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="totalCollected"
                                    value={generalList.totalCollected}
                                    onChange={(e) =>
                                        handleGeneralInputChange(e)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="justify-content-around">
                        <Col xs={4} className="my-1">
                            <Form.Group controlId="formEmployeeCredits">
                                <Form.Label>Employee Credits: </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="employeeCredits"
                                    value={generalList.employeeCredits}
                                    onChange={(e) =>
                                        handleGeneralInputChange(e)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4} className="my-1">
                            <Form.Group controlId="formHousingRentDue">
                                <Form.Label>Housing Rent Due: </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="housingRentDue"
                                    value={generalList.housingRentDue}
                                    onChange={(e) =>
                                        handleGeneralInputChange(e)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="justify-content-around">
                        <Col xs={4} className="my-1">
                            <Form.Group controlId="formCurrentMonthRentDue">
                                <Form.Label>
                                    Current Month Rent Due:{" "}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="currentMonthDue"
                                    value={generalList.currentMonthDue}
                                    onChange={(e) =>
                                        handleGeneralInputChange(e)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4} className="my-1">
                            <Form.Group controlId="formPreviousMonthRentDue">
                                <Form.Label>
                                    Previous Month Rent Due:{" "}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="previousMonthDue"
                                    value={generalList.previousMonthDue}
                                    onChange={(e) =>
                                        handleGeneralInputChange(e)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Container>
                {/* Move Ins */}
                <Container className="shadow p-3 mt-3">
                    <h4>Move Ins</h4>

                    {moveInList.map((x, i) => {
                        return (
                            <Form.Row key={i} className="ml-4">
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formMoveInUnitNumber">
                                        <Form.Control
                                            type="text"
                                            name="unitNumber"
                                            placeholder="Unit Number"
                                            value={x.unitNumber}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    moveInList,
                                                    setMoveInList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formMoveInDate">
                                        <Form.Control
                                            type="date"
                                            name="date"
                                            placeholder="Enter Date"
                                            value={x.date}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    moveInList,
                                                    setMoveInList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formMoveInRentalRate">
                                        <Form.Control
                                            type="number"
                                            name="rentalRate"
                                            placeholder="Enter Rental Rate"
                                            value={x.rentalRate}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    moveInList,
                                                    setMoveInList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                {moveInList.length !== 1 && (
                                    <Col xs={1}>
                                        <Button
                                            variant="outline-danger"
                                            className="mt-1"
                                            onClick={() =>
                                                handleRemoveClick(
                                                    i,
                                                    moveInList,
                                                    setMoveInList
                                                )
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                )}
                                {moveInList.length - 1 === i && (
                                    <Col xs={1}>
                                        <Button
                                            variant="outline-primary"
                                            className="mt-1"
                                            onClick={() =>
                                                handleAddClick(
                                                    moveInList,
                                                    setMoveInList,
                                                    "moveInList"
                                                )
                                            }
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                )}
                            </Form.Row>
                        );
                    })}
                </Container>

                {/* Move Outs */}
                <Container className="shadow p-3 mt-3">
                    <h4>Move Outs</h4>

                    {moveOutList.map((x, i) => {
                        return (
                            <Form.Row key={i} className="ml-4">
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formMoveOutUnitNumber">
                                        <Form.Control
                                            type="text"
                                            name="unitNumber"
                                            placeholder="Unit Number"
                                            value={x.unitNumber}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    moveOutList,
                                                    setMoveOutList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formMoveOutDate">
                                        <Form.Control
                                            type="date"
                                            name="date"
                                            placeholder="Enter Date"
                                            value={x.date}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    moveOutList,
                                                    setMoveOutList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formMoveOutRentalStatus">
                                        <Form.Control
                                            type="text"
                                            name="rentalStatus"
                                            placeholder="Rental Status"
                                            value={x.rentalStatus}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    moveOutList,
                                                    setMoveOutList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                {moveOutList.length !== 1 && (
                                    <Col xs={1}>
                                        <Button
                                            variant="outline-danger"
                                            className="mt-1"
                                            onClick={() =>
                                                handleRemoveClick(
                                                    i,
                                                    moveOutList,
                                                    setMoveOutList
                                                )
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                )}
                                {moveOutList.length - 1 === i && (
                                    <Col xs={1}>
                                        <Button
                                            variant="outline-primary"
                                            className="mt-1"
                                            onClick={() =>
                                                handleAddClick(
                                                    moveOutList,
                                                    setMoveOutList,
                                                    "moveOutList"
                                                )
                                            }
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                )}
                            </Form.Row>
                        );
                    })}
                </Container>

                {/* Expiring Leases */}
                <Container className="shadow p-3 mt-3">
                    <h4>Expiring Leases</h4>
                    <Row className="ml-4 mb-0">
                        <Col xs={{ span: 1 }}>
                            <Form.Label>Unit Number</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label>Date</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Form.Label>Prev Rate</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Form.Label>Post Rate</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label>Renewed?</Form.Label>
                        </Col>
                        <Col xs={3}>
                            <Form.Label>Note</Form.Label>
                        </Col>
                    </Row>
                    {expiringList.map((x, i) => {
                        return (
                            <React.Fragment>
                                <Form.Row key={i} className="ml-4">
                                    <Col xs={1} className="my-1">
                                        <Form.Group
                                            controlId="formExpiringUnitNumber"
                                            className="align-text-bottom"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="unitNumber"
                                                // placeholder="Unit"
                                                value={x.unitNumber}
                                                className="align-text-bottom"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        i,
                                                        expiringList,
                                                        setExpiringList
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={2} className="my-1">
                                        <Form.Group controlId="formExpiringDate">
                                            <Form.Control
                                                type="date"
                                                name="date"
                                                // placeholder="Date"
                                                value={x.date}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        i,
                                                        expiringList,
                                                        setExpiringList
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={1} className="my-1">
                                        <Form.Group controlId="formPreviousRentalRate">
                                            <Form.Control
                                                type="number"
                                                name="previousRentalRate"
                                                // placeholder="Prev Rental Rate"
                                                value={x.previousRentalRate}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        i,
                                                        expiringList,
                                                        setExpiringList
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={1} className="my-1">
                                        <Form.Group controlId="formPostRentalRate">
                                            <Form.Control
                                                type="number"
                                                name="postRentalRate"
                                                // placeholder="Post Rental Rate"
                                                value={x.postRentalRate}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        i,
                                                        expiringList,
                                                        setExpiringList
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={2} className="my-1">
                                        <Form.Group controlId="formRenew">
                                            <Form.Control
                                                as="select"
                                                custom
                                                value={x.renew}
                                                name="renew"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        i,
                                                        expiringList,
                                                        setExpiringList
                                                    )
                                                }
                                            >
                                                <option></option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={3} className="my-1">
                                        <Form.Group controlId="formRenewNote">
                                            <Form.Control
                                                type="text"
                                                name="renewNote"
                                                // placeholder="Note"
                                                value={x.renewNote}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        i,
                                                        expiringList,
                                                        setExpiringList
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    {expiringList.length !== 1 && (
                                        <Col xs={1}>
                                            <Button
                                                variant="outline-danger"
                                                className="mt-1"
                                                onClick={() =>
                                                    handleRemoveClick(
                                                        i,
                                                        expiringList,
                                                        setExpiringList
                                                    )
                                                }
                                            >
                                                Remove
                                            </Button>
                                        </Col>
                                    )}
                                    {expiringList.length - 1 === i && (
                                        <Col xs={1}>
                                            <Button
                                                variant="outline-primary"
                                                className="mt-1"
                                                onClick={() =>
                                                    handleAddClick(
                                                        expiringList,
                                                        setExpiringList,
                                                        "expiringList"
                                                    )
                                                }
                                            >
                                                Add
                                            </Button>
                                        </Col>
                                    )}
                                </Form.Row>
                            </React.Fragment>
                        );
                    })}
                </Container>
                {/* Delinquent Units */}
                <Container className="shadow p-3 mt-3">
                    <h4>Delinquent Units</h4>

                    {delinquentList.map((x, i) => {
                        return (
                            <Form.Row key={i} className="ml-4">
                                <Col xs={2} className="my-1">
                                    <Form.Group controlId="formDelinquentUnitNumber">
                                        <Form.Control
                                            type="text"
                                            name="unitNumber"
                                            placeholder="Unit Number"
                                            value={x.unitNumber}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    delinquentList,
                                                    setDelinquentList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={2} className="my-1">
                                    <Form.Group controlId="formDelinquentAmount">
                                        <Form.Control
                                            type="number"
                                            name="amountDelinquent"
                                            placeholder="Amount"
                                            value={x.amountDelinquent}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    delinquentList,
                                                    setDelinquentList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formDelinquentNotice">
                                        <Form.Control
                                            type="text"
                                            name="noticeToVacate"
                                            placeholder="Notice to Vacate"
                                            value={x.noticeToVacate}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    delinquentList,
                                                    setDelinquentList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formDelinquentCourtDate">
                                        <Form.Control
                                            type="text"
                                            name="courtDateInfo"
                                            placeholder="Court Date Info"
                                            value={x.courtDateInfo}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    delinquentList,
                                                    setDelinquentList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                {delinquentList.length !== 1 && (
                                    <Col xs={1}>
                                        <Button
                                            variant="outline-danger"
                                            className="mt-1"
                                            onClick={() =>
                                                handleRemoveClick(
                                                    i,
                                                    delinquentList,
                                                    setDelinquentList
                                                )
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                )}
                                {delinquentList.length - 1 === i && (
                                    <Col xs={1}>
                                        <Button
                                            variant="outline-primary"
                                            className="mt-1"
                                            onClick={() =>
                                                handleAddClick(
                                                    delinquentList,
                                                    setDelinquentList,
                                                    "delinquentList"
                                                )
                                            }
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                )}
                            </Form.Row>
                        );
                    })}
                </Container>

                {/* Eviction Units */}
                <Container className="shadow p-3 mt-3">
                    <h4>Eviction Units</h4>

                    {evictionList.map((x, i) => {
                        return (
                            <Form.Row key={i} className="ml-4">
                                <Col xs={2} className="my-1">
                                    <Form.Group controlId="formEvictionUnitNumber">
                                        <Form.Control
                                            type="text"
                                            name="unitNumber"
                                            placeholder="Unit Number"
                                            value={x.unitNumber}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    evictionList,
                                                    setEvictionList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={2} className="my-1">
                                    <Form.Group controlId="formEvictionAmount">
                                        <Form.Control
                                            type="number"
                                            name="amountDelinquent"
                                            placeholder="Amount"
                                            value={x.amountDelinquent}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    evictionList,
                                                    setEvictionList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formEvictionNotice">
                                        <Form.Control
                                            type="text"
                                            name="noticeToVacate"
                                            placeholder="Notice to Vacate"
                                            value={x.noticeToVacate}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    evictionList,
                                                    setEvictionList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-1">
                                    <Form.Group controlId="formEvictionCourtDate">
                                        <Form.Control
                                            type="text"
                                            name="courtDateInfo"
                                            placeholder="Court Date Info"
                                            value={x.courtDateInfo}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    i,
                                                    evictionList,
                                                    setEvictionList
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                {evictionList.length !== 1 && (
                                    <Col xs={1}>
                                        <Button
                                            variant="outline-danger"
                                            className="mt-1"
                                            onClick={() =>
                                                handleRemoveClick(
                                                    i,
                                                    evictionList,
                                                    setEvictionList
                                                )
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                )}
                                {evictionList.length - 1 === i && (
                                    <Col xs={1}>
                                        <Button
                                            variant="outline-primary"
                                            className="mt-1"
                                            onClick={() =>
                                                handleAddClick(
                                                    evictionList,
                                                    setEvictionList,
                                                    "evictionList"
                                                )
                                            }
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                )}
                            </Form.Row>
                        );
                    })}
                </Container>
                {/* Number of Vacant Units */}
                <Container className="shadow p-3 mt-3">
                    <h4>Number of Vacant Units</h4>
                    <Form.Row className="justify-content-start ml-4">
                        <Col xs={3} className="my-1">
                            <Form.Group controlId="formNumberOfVacantUnits">
                                <Form.Control
                                    type="number"
                                    name="numberOfVacantUnits"
                                    value={generalList.numberOfVacantUnits}
                                    onChange={(e) =>
                                        handleGeneralInputChange(e)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Container>
                {/* Submit Button */}
                <Container className="mt-3 d-flex justify-content-center">
                    <Button
                        variant="outline-primary"
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </Button>
                </Container>
            </Form>
            <Container className="mb-10">
                <p className="text-center text-muted">__</p>
            </Container>

            <Modal
                show={showSubmissionComplete}
                onHide={handleCloseSubmissionComplete}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Weekly Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Submission Complete! Please check your dashboard to make
                    sure submitted information is correct.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        onClick={handleCloseSubmissionComplete}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default SubmitReport;
