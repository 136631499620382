import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

function NavBarCustom(props) {
	return (
		<Navbar
			bg="dark"
			variant="dark"
			sticky="top"
			className="d-flex justify-content-between"
		>
			<Nav className="mr-auto">
				<Nav.Link
					onClick={() => {
						props.setView("submit");
					}}
					className="mr-4"
				>
					Submit Report
				</Nav.Link>
				<Nav.Link
					onClick={() => {
						props.setView("dashboard");
					}}
					className="mr-4"
				>
					Dashboard
				</Nav.Link>
				{props.admin ? (
					<Nav.Link
						onClick={() => {
							props.setView("admin");
						}}
					>
						Admin
					</Nav.Link>
				) : (
					<React.Fragment></React.Fragment>
				)}
			</Nav>
			<Nav>
				<Nav.Link
					onClick={() => {
						props.firebase.app().auth().signOut();
					}}
				>
					Sign Out
				</Nav.Link>
			</Nav>
		</Navbar>
	);
}

export default NavBarCustom;
